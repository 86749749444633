import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HsConfigObject} from 'hslayers-ng';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  forkJoin,
  of,
} from 'rxjs';
import {aoiLayer, bookmarkLayer} from '../layers/layers';
import View from 'ol/View';
import {transform} from 'ol/proj';

/**
 * @hslayersConfig HSLayers onfiguration object. Add or rewrite defaults
 * @composition {@link https://github.com/hslayers/map-compositions HS Composition object}
 * @default_view Default view zoom and center (WGS:84 coordinates) settings
 * @logo Logo soruce and options
 */
export interface fieConfig {
  hslayersConfig: HsConfigObject;
  composition: any;
  default_view: {
    zoom: number;
    center: [number, number];
  };
  logo: {
    src: string;
    href: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FieConfigService {
  private configSubject = new BehaviorSubject<fieConfig>(undefined);

  readonly assetsPath = `assets/`;
  readonly config$ = this.configSubject
    .asObservable()
    .pipe(filter((config) => !!config));

  get config() {
    return this.configSubject.getValue();
  }

  private baseConfig = {
    //COMMON SETTINGS
    assetsPath: this.assetsPath + 'hslayers-ng',
    proxyPrefix: window.location.hostname.includes('localhost')
      ? `${window.location.protocol}//${window.location.hostname}:8085/`
      : '/proxy/',
    searchProvider: (q) => {
      return `/proxy/search/?q=${q}`;
    },
    layersInFeatureTable: [aoiLayer],
    project_name: 'erra/map',
    open_lm_after_comp_loaded: true,
    default_layers: [bookmarkLayer, aoiLayer],
    popUpDisplay: 'click',
    panelWidths: {
      sensors: 600,
    },
    layerTooltipDelay: 1500,
    saveMapStateOnReload: false,
    timeDisplayFormat: 'dd.MM.yyyy',
    sidebarPosition: 'right',
  };

  hslayersConfig;

  constructor(private http: HttpClient) {}

  getSubdomain(): string {
    return window.location.host.split('.')[0];
  }

  fetchEndpoints() {
    const configURL = `${this.assetsPath}config.json`;
    const subdomain = this.getSubdomain();

    const config: Observable<any> = this.http.get(configURL).pipe(
      catchError((error) => {
        return of({
          hslayersConfig: undefined,
          composition: undefined,
          default_view: {zoom: 10, center: [0, 0]},
        });
      })
    );

    const translations: Observable<any> = this.http
      .get(`${this.assetsPath}translations.json`)
      .pipe(
        catchError((error) => {
          return of(false);
        })
      );

    forkJoin([config, translations]).subscribe({
      next: ([config, translationOverrides]) => {
        const fieConfig = config[subdomain] || config['default'];
        this.configSubject.next(fieConfig);
        this.baseConfig['default_view'] = this.createDefaultView(fieConfig);
        this.baseConfig['translationOverrides'] = translationOverrides;
        this.hslayersConfig = {
          ...this.baseConfig,
          ...fieConfig.hslayersConfig,
        };
      },
      error: (e) =>
        this.configSubject.next({
          hslayersConfig: undefined,
          composition: undefined,
          default_view: {zoom: 10, center: [0, 0]},
          logo: {
            src: '',
            href: '',
          },
        }),
    });
  }

  private createDefaultView(fieConfig: fieConfig) {
    return new View({
      center: transform(
        fieConfig.default_view.center || [25.8540647, 57.4596875],
        'EPSG:4326',
        'EPSG:3857'
      ), //Latitude longitude to Spherical Mercator
      zoom: fieConfig.default_view.zoom || 15,
    });
  }
}
