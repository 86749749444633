import {Injectable} from '@angular/core';
import {
  HsConfig,
  HsPanelContainerService,
  HsDrawService,
  HsEventBusService,
  HsCompositionsParserService,
  HsMapService,
  HsLayerManagerService,
  HsLayerShiftingService,
  HsLayerDescriptor,
  HsCapabilityCacheService,
  HsUtilsService,
  HsLayerManagerMetadataService,
} from 'hslayers-ng';
import {HsSensorsComponent} from 'hslayers-sensors';
import {FieConfigService} from './initializer/fie.config.service';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import projx from 'proj4';

const proj4 = projx.default ?? projx;

@Injectable({
  providedIn: 'root',
})
export class MainService {
  logo = 'logo.png';

  constructor(
    private hsPanelContainerService: HsPanelContainerService,
    private HsConfig: HsConfig,
    private HsDrawService: HsDrawService,
    private FieConfigService: FieConfigService,
    private hsCompositionParserService: HsCompositionsParserService,
    private hsMapService: HsMapService,
    private hsLayerManagerService: HsLayerManagerService,
    private layerShiftingService: HsLayerShiftingService,
    private http: HttpClient,
    private hsEventBusService: HsEventBusService,
    private hsCapabilityCacheService: HsCapabilityCacheService,
    private hsUtilsService: HsUtilsService,
    private hsLayerManagerMetadata: HsLayerManagerMetadataService
  ) {

    proj4.defs("EPSG:3059","+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
    proj4.defs(
      'http://www.opengis.net/gml/srs/epsg.xml#3059',
      proj4.defs('EPSG:3059'),
    );

    const images = this.FieConfigService.assetsPath + 'images/';
    const configLogo = this.FieConfigService.config.logo.src;
    this.logo =
      configLogo.length > 0
        ? `${images}/${configLogo}`
        : `${this.FieConfigService.getSubdomain()}logo.png`;

    this.hsEventBusService.layerAdditions.subscribe(
      async (descriptor: HsLayerDescriptor) => {
        if (descriptor.title == 'Drenu_sistēma') {
          console.log(descriptor);
          const url = `${descriptor.source}?f=json`; //request=GetCapabilities&service=WMS&version=1.3.0
          const r = await lastValueFrom(
            this.http.get(url, {
              responseType: 'json',
              observe: 'response', // Set observe to 'response' to get headers as well
            })
          );
          const wrap = {response: r.body};
          this.hsCapabilityCacheService.set(
            this.hsUtilsService.proxify(url),
            wrap
          );

          await this.hsLayerManagerMetadata.fillMetadata(descriptor);
          descriptor.grayed =
            !this.hsLayerManagerService.isLayerInResolutionInterval(
              descriptor.layer
            );
        }
      }
    );

    const fieConfig = this.FieConfigService.config;
    this.hsMapService.loaded().then(async (map) => {
      this.hsPanelContainerService.create(HsSensorsComponent, {
        viewMode: 'sensornames',
      });

      if (fieConfig.composition) {
        await this.hsCompositionParserService.loadCompositionObject(
          {...fieConfig.composition, basemapComposition: true},
          false
        );
        console.log(this.hsLayerManagerService.data.layers);
        /**
         * Set zIndex of layers
         */
        this.hsLayerManagerService.data.baselayers.forEach((l) => {
          this.layerShiftingService.moveToBottom(l.layer);
        });
      }
    });

    this.HsConfig.defaultDrawLayerPath = 'User generated';
    //setupLayers(this.HsLanguageService, this.HsShareUrlService, this.FieAnalysisService, this.HsToastService);
    //meteoLegends(this.HsConfig.default_layers);
  }
  getHostname() {
    var url = window.location.href;
    var urlArr = url.split('/');
    var domain = urlArr[2];
    return urlArr[0] + '//' + domain;
  }
}
