import {APP_INITIALIZER, NgModule} from '@angular/core';
import {take} from 'rxjs';
import {FieConfigService} from './fie.config.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (config: FieConfigService) => {
        return () => {
          config.fetchEndpoints();
          return config.config$.pipe(take(1));
        };
      },
      deps: [FieConfigService],
    },
  ],
})
export class InitializerModule {}
