import {Component} from '@angular/core';
import {
  HsLanguageService,
  HsLayoutService,
  HsSidebarService,
} from 'hslayers-ng';
import './analysis/analysis.module';
import {MainService} from './main.service';
import {HsSensorsService} from 'hslayers-sensors';
import {FieConfigService} from './initializer/fie.config.service';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styles: [
    `
      hslayers {
        height: 100vh;  
        height: 100svh; 
        display:block
      }
    `
  ]
})
export class AppComponent {
  constructor(
    private HsLayoutService: HsLayoutService,
    private HsSidebarService: HsSidebarService,
    public HsLanguageService: HsLanguageService,
    public MainService: MainService,
    private HsSensorsService: HsSensorsService,
    public fieConfigService: FieConfigService
  ) {
    this.HsLayoutService.setDefaultPanel('layermanager');
    // this.HsSidebarService.sidebarLoad.subscribe(() => {
    //   this.HsPanelContainerService.create(FieAnalysisPanelComponent, {});
    // });

    // this.HsEventBusService.layermanagerDimensionChanges.subscribe((args) => {
    //   meteoblueDimensionChanged(
    //     args,
    //     this.HsShareUrlService,
    //     this.HsUtilsService
    //   );
    // });
    // this.HsEventBusService.mapClicked.subscribe((e) => {
    //   mapClicked(e, this.HsMapService, this.$http);
    // });

    // this.HsSidebarService.addButton({ panel: 'analysis', order: 4, title: this.HsLanguageService.getTranslation('Analysis'), description: HsLanguageService.getTranslation('Run analysis'), icon: 'icon-alertpay' })

    this.HsSidebarService.buttons.subscribe((buttons) => {
      this.HsSidebarService.setButtonImportancy(buttons, 'legend', false);
      this.HsSidebarService.setButtonImportancy(
        buttons,
        'feature_table',
        false
      );
      this.HsSidebarService.setButtonImportancy(buttons, 'permalink', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'info', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'saveMap', false);
      this.HsSidebarService.setButtonImportancy(buttons, 'language', false);
      const sensorsButton = buttons.find((b) => b.panel == 'sensors');
      if (sensorsButton) {
        sensorsButton.order = -0.5;
      }
    });
    // updateDimensionOptions(
    //   [...vegetationLayers, landValue],
    //   this.$http,
    //   this.HsUtilsService,
    //   this.HsEventBusService
    // );
  }
}
