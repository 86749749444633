import { GeoJSON } from 'ol/format';
import { Style, Icon, Stroke, Fill } from 'ol/style';
import genLabelStyle from './gen-label-style';
// import { meteoblueLayers } from './meteo-layers';
import { getArea } from 'ol/sphere';
import * as extent from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource, TileWMS } from 'ol/source';
import { vegetationLayers, vegetationLayersLesprojekt } from './vegetation-layers';
import { Tile } from 'ol/layer';
import { OSM, XYZ, TileArcGISRest, Source } from 'ol/source';
import Feature from 'ol/Feature';
import * as filtered from './filtered.json';
// import { FieAnalysisService } from '../analysis/analysis.service';
import { HsEventBusService, HsToastService, defaultStyle } from 'hslayers-ng';
import { HsUtilsService } from 'hslayers-ng';
import { HttpClient } from '@angular/common/http';
import { Geometry } from 'ol/geom';
import { lastValueFrom } from 'rxjs';

var labelStyle = genLabelStyle;
const assetsPath = (<any>window).assetsPath ? (<any>window).assetsPath : (window.location.hostname.indexOf('localhost') > -1
  ? `assets/`
  : 'assets/');

var bookmarkStyle = [new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: '#e49905',
    width: 2
  }),
  image: new Icon({
    src: assetsPath + 'images/mrkr-bookmark.png',
    crossOrigin: 'anonymous',
    anchor: [0.5, 1]
  })
}), labelStyle];

var aoiStyle = [new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: '#4e7eda',
    width: 2
  }),
  image: new Icon({
    src: assetsPath + 'images/mrkr-interest.png',
    crossOrigin: 'anonymous',
    anchor: [0.5, 1]
  })
}), labelStyle];

// const geojsonFormat = new GeoJSON();
// const aoiFeatures = geojsonFormat.readFeatures(filtered, {
//   dataProjection: 'EPSG:4326',
//   featureProjection: 'EPSG:3857',
// });

export const aoiLayer = new VectorLayer({
  properties: {
    title: 'Areas of interest',
    name: 'areas_of_interest',
    definition: {
      format: 'hs.format.WFS',
      url: location.origin + '/layman/client/geoserver/test/wfs'
    },
    popUp: {
      attributes: ['name', 'PIETEIKTAI', 'PIETEIKTIE', 'PIETEIKTA_'],
    },
    path: 'User generated',
    sld: defaultStyle
  },
  source: new VectorSource(), //{features: aoiFeatures}

  /**
   * defaultStyle is the same (only labels are missing, but in this case they were empty)
   * Style function throws on drawService.hasRequiredSymbolizer as well
   */
  // style: function (feature) {
  //   labelStyle.getText().setText(feature.get('name'));
  //   return aoiStyle;
  // },
});

export const bookmarkLayer = new VectorLayer({
  properties: {
    title: 'Bookmarks',
    definition: {
      format: 'hs.format.WFS',
      url: location.origin + '/layman/client/geoserver/test/wfs'
    },
    popUp: {
      attributes: ['name'],
    },
    editor: {
      editable: true,
      defaultAttributes: {
        name: 'New bookmark',
        description: 'none'
      }
    },
    path: 'User generated',
    inlineLegend: true,
  },
  source: new VectorSource({}),
  style: function (feature) {
    labelStyle.getText().setText(feature.get('name'));
    return bookmarkStyle;
  },
});

export const landValue = new Tile({
  properties: {
    title: "Land value:",
    from_composition: true,
    legends: assetsPath + 'images/soil-legend.png',
    path: 'Other',
    editor: { editable: false },
    inlineLegend: true,
  },
  source: new XYZ({
    attributions: '&copy; <a href="https://geolatvija.lv/geo/p/317">Zemkopības ministrija</a>',
    url: 'https://api.mapbox.com/styles/v1/recon517/ck0vs82zx2pw51cmz4yjh73kn/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',
  }),

  visible: false,
  opacity: 0.7
});


// export const opticalBaseMap = new Tile({
//   properties: {
//     title: "Optical satellite basemap",
//     from_composition: true,
//     dimensions: {
//       time: {
//         value: '2020-11-20',
//         name: 'time',
//         values: ['2020-11-20']
//       }
//     },
//     base: false,
//     editor: { editable: false },
//     path: 'Vegetation indexes and satellite imagery',
//   },

//   source: new XYZ({
//     attributions: '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
//     url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-RGB/{z}/{x}/{y}.png',
//   }),
//   maxZoom: 18,
//   visible: false,
//   opacity: 1
// });
// export const infrared = new Tile({
//   properties: {
//     title: "Infrared satellite map (Vilcini)",
//     from_composition: true,
//     dimensions: {
//       time: {
//         value: '2020-11-20',
//         name: 'time',
//         values: ['2020-11-20']
//       }
//     },
//     editor: { editable: false },
//     path: 'Vegetation indexes and satellite imagery'
//   },
//   source: new XYZ({
//     attributions: '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
//     url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-CIR/{z}/{x}/{y}.png',
//   }),
//   maxZoom: 18,
//   visible: false,
//   opacity: 1,
// });
export const default_layers = [
  new Tile({
    properties: {
      title: 'Ortophoto LVM',
      base: true,
    },
    source: new TileWMS({
      url: 'https://lvmgeoserver.lvm.lv/geoserver/ows?service=wms&version=1.3.0',
      params: {
        LAYERS: 'public:Orto_LKS',
        INFO_FORMAT: undefined,
        FORMAT: 'image/png; mode=8bit',
      },
      crossOrigin: 'anonymous',
    }),
    visible: true,
    opacity:1
  }),
  new Tile({
    properties: {
      title: "Open street map",
      base: true,
      editor: { editable: false },
      removable: false
    },
    source: new OSM(),
    visible: false,
  }),
  new Tile({
    properties: {
      title: "Street map",
      editor: { editable: false },
      base: true,
    },
    source: new XYZ({
      attributions: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      ,
      url: 'https://api.mapbox.com/styles/v1/recon517/ck0uew4wrgigc1dl7pkvnfmvi/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',
    }),
    visible: false,
    opacity: 1
  }),
  //opticalBaseMap,
  new Tile({
    properties: {
      title: "Soil class:",
      from_composition: true,
      abstract: 'soilLayerAbstract',
      path: 'Other',
      editor: { editable: false },
    },
    source: new XYZ({
      attributions: '&copy; <a href="https://geolatvija.lv/geo/p/317">Zemkopības ministrija</a>',
      url: 'https://api.mapbox.com/styles/v1/recon517/ck0v9ehr90phy1cqzu3hzkmmc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',
    }),
    visible: false,
    opacity: 0.7
  }),
  landValue,
  //infrared,
  //...vegetationLayers,
  //...vegetationLayersLesprojekt,
  ...[
    new Tile({
      properties: {
        title: 'Natura-2000 protected areas',
        editor: { editable: false },
        path: 'Other',
      },
      source: new XYZ({
        url: 'https://api.mapbox.com/styles/v1/recon517/ck0yrgg0l0o2s1dnwgm3dc3ng/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',
      }),
      visible: true,
      opacity: 0.7
    }),
    new Tile({
      properties: {
        title: 'Field blocks',
        from_composition: true,
        path: 'Other',
      },
      visible: true,
      opacity: 0.8,
      extent: [2341344.84, 7492503.59, 3164273.66, 7968605.48],
      source: new TileArcGISRest({
        url: 'https://karte.lad.gov.lv/arcgis/rest/services/karte_pub/MapServer',
        params: {
          LAYERS: 'show:6'
        }
      })
    }),
    new Tile({
      properties: {
        title: "Water bodies and rivers",
        from_composition: true,
        editor: { editable: false },
        path: 'Other',
      },
      source: new XYZ({
        attributions: '&copy; <a href="https://opendata.lgia.gov.lv/zf_wp/index.php/2018/08/03/topografiska-karte-meroga-150-000-2-izdevums/">LĢIA</a>',
        url: 'https://api.mapbox.com/styles/v1/recon517/ck0sz2nv11nu91cqp4w4mdxrk/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',

      }),
      visible: true,
      opacity: 1
    })
    // new Tile({
    //   properties: {
    //     title: 'ZS "Vilciņi" border',
    //     from_composition: true,
    //     editor: { editable: false },
    //     path: 'Other',
    //   },
    //   source: new XYZ({
    //     url: 'https://api.mapbox.com/styles/v1/recon517/ck0udcjhj6zb61cqlcvtdirmq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVjb241MTciLCJhIjoiY2l4cXBpbzZtMDAzNDMybDY2YnAzdjlndSJ9.MRq_ohDyD2x5t5DdlAwytA',

    //   }),
    //   visible: true,
    //   opacity: 0.7
    // })
  ],
  //...meteoblueLayers,
  ...[
    bookmarkLayer,
    aoiLayer
  ]
];


// export function setupLayers(HsLanguageService, HsPermalinkUrlService, FieAnalysisService: FieAnalysisService, HsToastService: HsToastService){
//   aoiLayer.set('editor', {
//     editable: true,
//     defaultAttributes: {
//       name: HsLanguageService.getTranslation('New area'),
//       description: '-'
//     }
//   });
//   aoiLayer.set('virtualAttributes', {
//     area: function(feature: Feature<Geometry>){
//       const geom = feature.getGeometry();
//       if(geom){
//         return (getArea(geom) / 10000).toFixed(2) + 'ha';
//       } else {
//         return '';
//       }
//     },
//     link: function(feature: Feature<Geometry>){
//       const urlParams = HsPermalinkUrlService.parse(location.search);
//       const center = extent.getCenter(feature.getGeometry().getExtent());
//       urlParams.hs_x = center[0];
//       urlParams.hs_y = center[1];
//       const url = `${HsPermalinkUrlService.pathName()}?${HsPermalinkUrlService.stringify(urlParams)}`;
//       return `<a href="${url}" target="_blank">${HsLanguageService.getTranslation('open')}</a>`;
//     },
//     actions: function(feature: Feature<Geometry>){
//       return {operations: [
//         {action: 'zoom to', feature},
//         {action: 'custom action', 
//           customActionName: 'Describe ndvi',
//           feature, 
//           customAction: async (feature) => {
//             try {
//               const response: any = await FieAnalysisService.executeNdviStats(feature)
//               feature.set('average ndvi', response.avg);
//               feature.set('max ndvi', response.max);
//               feature.set('min ndvi', response.min);
//             } catch(ex){
//               HsToastService.show(
//                 HsLanguageService.getTranslation('FieldHistoryDataDidntExist'),
//                 'default',
//                 {
//                   header: HsLanguageService.getTranslation(
//                     'Analysis failed'
//                   ),
//                   delay: 3000,
//                   autohide: true,
//                   classname: 'bg-warning text-light',
//                 }
//               );
//             }
//           }
//         }
//       ]}
//     }
//   })
// }

export async function updateDimensionOptions(layers: any[], httpClient: HttpClient, hsUtils: HsUtilsService, hsEventBusService: HsEventBusService) {
  const response: any = await lastValueFrom(httpClient.get(hsUtils.proxify('https://fr-cdn.ams3.cdn.digitaloceanspaces.com/zs-fie-xNFwHfJdIR1dCtA7kJ1K8g/tile-data.json')));
  for (const layer of layers) {
    const dimensions = layer.get('dimensions');
    if (dimensions) {
      layer.get('dimensions').time.values = response.img_dates;
    }
    hsEventBusService.layerDimensionDefinitionChanges.next(layer);
  }
}