import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HslayersModule } from 'hslayers-ng';
import { NgModule } from '@angular/core';
// import { AnalysisModule } from './analysis/analysis.module';
import { HsSensorsModule } from 'hslayers-sensors';
import { InitializerModule } from './initializer/initializer.module';

@NgModule({
  imports: [BrowserModule, HslayersModule,  HsSensorsModule, InitializerModule],
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
